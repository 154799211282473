.skills-section{
    height: max-content;
    min-height: 730px;
    width: 100%;
    background-color: rgb(240,240,240);
    font-family: 'Roboto Mono', monospace;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skills-list-container{
    height: 100%;
    max-height: max-content;
    width: 100%;
    padding: 5% 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:60px;
}

.skills-title{
    font-size: 40px;
    margin-bottom: 25px;
}

.sklls-explntn{
    color: rgb(120,120,120);
    font-size: 20px;
    line-height: 33px;
}

.skills-list{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:30px;
}

.skllPrfcncy-container{
    height: auto;
    width: 100%;
    padding: 3% 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
}

.prfcncy-classification{
    text-indent: 1%;
    font-weight: 600;
} 

.skllPrfcncy{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    align-items: center;
    column-gap: 5%;
}

.skill{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skill img{
    width: 150px;
}

.skill h3{
    font-size: 20px;
}

@media (max-width:1200px) {
    .skills-list-container{
        padding: 8% 5%;
        gap:40px !important;
    }
}

@media (max-width:950px) {
    
    .skllPrfcncy{
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        column-gap: 5%;
    }

    .skill img{
        width: 120px;
    }

    .skill h3{
        font-size: 18px;
    }
    
}

@media (max-width:700px) {
    .skills-list-container{
        gap:30px !important;
    }

    .skills-title{
        font-size: 35px;
    }

    .sklls-explntn{
        font-size: 1rem;
    }
}

@media (max-width:510px) {

    .skills-title{
        font-size: 30px;
    }

    .sklls-explntn{
        font-size: 14px;
    }

    .skllPrfcncy{
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        column-gap: 5%;
    }

    .skill img{
        width: 100px;
    }

    .skill h3{
        font-size: 16px;
    }
}

@media (max-width:450px) {
    .skllPrfcncy{
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
        column-gap: 5%;
    }

    .skill img{
        width: 80px;
    }

    .skill h3{
        font-size: 12px;
    }
}