.laptop-screen{
    width: 97%;
    height: 89%;
    position: relative;
    border: 1px solid rgba(30,30,30,.3);
    color: white;
    background: url('../../../../Assets/images/lptp_pg/gamers_bg/wallpaper-acer-nitro-with-new-logo-v0-u1nulqskf5ac1.webp') no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* animation: openingProfile 2s 6s; */
    z-index: 2;
    overflow: hidden;
    cursor: default;
}

.laptop-screen.activeBrwsr{
    overflow: visible;
    cursor: default;
}

.myprofile-icon{
    height: max-content;
    width: 25%;
    padding: 5px;
    position: absolute;
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.myprofile-icon.active{
    background-color: rgba(255,255,255,.4);
    border-radius: 5px;
    transition: background-color .4s;
}

.myprofile-icon h4{
    margin: 0;
    font-size: 1.2rem;
    font-weight: 100;
}

.myprofile-icon:hover{
    background-color: rgba(255,255,255,.2);
    border-radius: 5px;
    transition: background-color .4s;
}

.dbleClckMeBttn{
    position: absolute;
    height: max-content;
    width: 30%;
    right: .2%;
    bottom: 11%;
    margin: 0;
    border-radius: 5px;
    background-color: rgba(100,100,100,.6);
    animation: messgeToClick 1.5s;
    z-index: -1;
}

.dbleClckMeBttn.hidden{
    position: absolute;
    height: max-content;
    width: 30%;
    right: .2%;
    bottom: 11%;
    margin: 0;
    border-radius: 5px;
    background-color: rgba(100,100,100,.6);
    animation: hideImpMessge 1.5s;
    z-index: -1;
}

@keyframes messgeToClick {
    0%{
        transform: translateX(100%);
    }50%{
        transform: translateX(-10%);
    }100%{
        transform: translateX(0);
    }
}

@keyframes hideImpMessge {
    0%{
        transform: translateX(0%);
    }50%{
        transform: translateX(-10%);
    }100%{
        transform: translateX(100%);
    }
}

.mssgeTitleToClick{
    width: 100%;
    position: relative;
    padding: 5px 10px;
    box-shadow: 0 -5px 20px 0px rgba(20,20,20,1);
    display: flex;
    align-items: center;
    gap:5px;
}

.mssgeTitleToClick i{
    font-size: 1rem;
}

.mssgeTitleToClick h5{
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
}

.clseMessge-notif{
    position: absolute;
    right: 0;
    transform: translateX(-25%);
    display: flex;
    align-items: center;
}

.clseMessge-notif i:hover{
    border-radius: 5px;
    background-color: rgba(255,255,255,.2);
    transition: background-color .2s;
    
}

.dbleClckMeBttn{
    cursor: default;
}

.dbleClckMeBttn p{
    height: max-content;
    padding: 10px;
    font-size: 15px;
    font-weight: 400;
}

.desktop.chrome-icon{
    height: 100%;
    min-height: 100px;
    width: 75%;
    background: url('../../../../Assets/images/lptp_pg/chrome_icon.png') no-repeat;
    background-position: center;
    background-size:100% 100%;
    cursor: pointer;
}

.taskbar{
    height: 10%;
    width: 100%;
    bottom: 0;
    position: absolute;
    background-color: rgba(255,255,255,.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftTask-bar{
    height: 100%;
    width: 50%;
    display: flex;
}

.taskbar-button{
    height: 100%;
}

.taskbar-button.start-icon{
    width: 10%;
    background: url('../../../../Assets/images/lptp_pg/Windows-11-Win-X-Menu-icon-removebg-preview.png') no-repeat;
    background-position: center;
    background-size: cover;
}

.taskbar-button.search-icon{
    width: 10%;
    background: url('../../../../Assets/images/lptp_pg/images-removebg-preview.png') no-repeat;
    background-position: center;
    background-size:50% 50%;
    transition: transform 1s;
}

.taskbar-button.chrome-icon{
    width: 10%;
    position: relative;
    background: url('../../../../Assets/images/lptp_pg/chrome_icon.png') no-repeat;
    background-position: center;
    background-size:70% 70%;
    cursor: pointer;
}

.taskbar-button.chrome-icon:hover, .taskbar-button.chrome-icon.shwMx{
    background-color: rgba(255,255,255,.2);
    border-radius: 5px;
    transition: background-color .3s;
}

.taskbar-button.chrome-icon.shwMx::after{
    content: "";
    position: absolute;
    height: 1px !important;
    width: 60% !important;
    bottom: 0;
    left: 0;
    border: 1px solid #4CC2FF;
    border-radius: 100px;
    background-color: #4CC2FF;
    transform: translateX(35%);
    animation: shwLengthLine .5s;
}


.taskbar-button.chrome-icon.shwMin::after{
    content: "";
    position: absolute;
    height: 1px !important;
    width: 25% !important;
    bottom: 0;
    left: 0;
    border: 1px solid #4CC2FF;
    border-radius: 100px;
    background-color: #4CC2FF;
    transform: translateX(150%);
    animation: shwLengthLine .5s;
}

@keyframes shwLengthLine {
    from{
        opacity: 0;
    }to{
        opacity:1;
    }
}


.rightTask-bar{
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.shwhddn-container{
    height: 100%;
    display: flex;
    align-items: center;
}

.shwhddn-container i{
    font-size: .7rem;
}

.langIndicator-container{
    height: 100%;
    margin: 0 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.langIndicator-container > * {
    width: 100%;
    margin: 0;
    font-size: 7px;
    font-weight: 500;
    text-align: center;
}

.batWFVol-container{
    height: 100%;
    width: max-content;
    display: flex;
    gap:5px
}

.batWFVol-container > *{
    height: 100%;
    width: max-content;
    display: flex;
    align-items: center;
}

.wifi-container i, .vol-container i, .bat-container i{
    height: 100%;
    width: 100%;
    font-size: .95rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dtTimeNotif-container{
    height: 80%;
    width: max-content;
    margin: 1%;
    padding-inline: 1.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px
}

.dtTimeNotif-container:hover{
    background-color: rgba(255,255,255,.1);
    border-radius: 2px;
    transition: background-color .2s;
}

.dteAndTme-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.dteAndTme-container p{
    margin: 0;
    font-size: .53rem;
    font-weight: 400;
}

.notifIcon-container{
    height: 100%;
    margin: 0 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notifIcon-container i{
    height: 100%;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}





@media (max-height: 800px) {
    .myprofile-icon{
        width: 22%;
        padding: 1%;
    }

    .desktop.chrome-icon{
        min-height: 88px;
    }

    .myprofile-icon{
        height: max-content;
    }

    .myprofile-icon h4{
        text-align: center;
        font-size: 1.2rem;
    }
}

@media (max-height: 720px) {
    .myprofile-icon{
        height: 50%;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
    }

    .desktop.chrome-icon{
        min-height: 48px;
        width: 85%;
    }

    .myprofile-icon h4{
        font-size: 1rem;
    }
}

@media (max-height: 670px), (max-width: 950px) {

    .myprofile-icon{
        
        height: 35%;
    }


    .desktop.chrome-icon{
        min-height: 35px !important;
    }

    .myprofile-icon h4{
        font-size: .6rem;
        font-weight: 100 !important;
    }

    .dbleClckMeBttn {
        height: 58%;
        width: 30%;
        border-radius: 2px;
    }

    .mssgeTitleToClick{
        width: 100%;
        position: relative;
        padding: 5px;
        box-shadow: 0 -5px 20px 0px rgba(20,20,20,1);
        display: flex;
        align-items: center;
        gap:5px;
    }
    
    .mssgeTitleToClick i{
        font-size: .7rem;
    }
    
    .mssgeTitleToClick h5{
        margin: 0;
        font-size: .7rem;
        font-weight: 500;
    }

    .clseMessge-notif{
        transform: translateX(-30%);
    }

    .clseMessge-notif i{
        border-radius: 2px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dbleClckMeBttn p{
        height: max-content;
        padding: 6px;
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .leftTask-bar > *{
        width: 11% !important;
    }

    .shwhddn-container i{
        font-size: .4rem;
    }

    .langIndicator-container{
        margin: 0 2%;
    }

    .langIndicator-container p{
        font-size: 5px;
        font-weight: 200;
    }

    .batWFVol-container{
        gap:3px
    }

    .wifi-container i, .vol-container i, .bat-container i{
        height: 100%;
        width: 100%;
        font-size: .6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dtTimeNotif-container{
        gap:3px
    }

    .dteAndTme-container p{
        font-size: .4rem;
        font-weight: 300;
    }

    .notifIcon-container i{
        font-size: 9px;
    }

    
}