.myProfile-page {
    width: 100%;
    position: relative;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: slwlyShwngProfile 1s;
}

@keyframes slwlyShwngProfile {
    from{
        opacity: 0;
    }to{
        opacity: 1;

    }
}



::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
    background-color: rgba(220,220,220,.6);
    border: 1px solid white;
    box-shadow: inset 0 0 10px ;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.submitDelay-container{
    height: 100%;
    width: 100%;
    position: fixed;
    padding: 0 40px;
    background-color: rgba(0,0,0,.9);
    color: white;
    text-align: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    z-index: 1;
}

.submitDelay-container h4{
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
}

.spinner-border.m-5{
    height: 100px !important;
    width: 100px !important;
    margin: 0 !important;
}
