.home-section{
    width: 100%;
    position: relative;
    background-color: aqua;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.backgroundPicture{
    height: 100% !important;
    width: 100%;
    position: absolute;
    box-sizing: border-box;
    background: url('../../../../Assets/images/my_pic.jpg') center 0px / cover no-repeat ;
    /* background-position: center; */
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(50px);
}

.backgroundPicture::before{
    height: 100% !important;
    width: 100%;
    content: "";
    position: absolute;
    top:0;
    left:0;
    margin: 0;
    box-sizing: border-box;
    background-color: rgba(30,30,30,.8);
}

.homeContent-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    color: white;
    z-index: 0;
}

.homeContent-placer{
    height: 100vh;
    min-height: 730px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:50px;    
}

.homeContent-container h1{
    font-family: 'Roboto Mono', monospace;
    font-size: 60px;
    font-weight: 600;
}

.homeContent-placer p{
    width: 50%;
    font-family: 'Roboto Mono', monospace;
    font-size: 24px;
}

.chngingSkills-container{
    height: max-content;
    width: 100%;
    font-family: 'Roboto Mono', monospace;
    font-size: 35px;
}

.showSkill-container{
    height: max-content;
    width: max-content;
    display: flex;
}

.hideSkill{
    width: max-content;
    display: none;
}

.showSkill{
    width: 0;
    margin: 0;
    padding-right: 10px;
    display: flex;
    align-items: center;
    animation: showTypingSkills 1s ease-in-out forwards, hideTypingSkills 1s ease-in-out 1.9s forwards;
    border-right: 1px solid white;
    overflow: hidden;
    white-space: nowrap;
}

@keyframes showTypingSkills {
    from {
        width: 0;
        padding: 0;
    }
    to {
        width: 100%;
        padding-right: 10px;
    }
}

@keyframes hideTypingSkills {
    from {
        width: 100%;
    } to {
        padding: 0;
        width: 0;
    }
}

#cloudsSeparator {
    height:  100px;
    width: 100%;
    position: absolute;
    bottom: -2%;
    z-index: 0;
}



@media (max-width: 1700px) {
    .homeContent-placer p{
        width: 60%;
        font-size: 25px;
    }
}

@media (max-width: 1500px){
    .homeContent-placer p{
        width: 70%;
    }

    .chngingSkills-container{
        font-size: 30px;
    }
}


@media (max-width:1200px) {
    .homeContent-placer p{
        width: 80%;
    }

    .chngingSkills-container{
        font-size: 30px;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width:1100px) {

    .homeContent-placer{
        width: 70%;
    }

    .homeContent-placer p{
        width: 80%;
        font-size: 25px;
    }

    .chngingSkills-container{
        font-size: 30px;
        display: flex;
        flex-direction: column;
    } 

    .chngingSkills{
        display: inline !important;
    }

    .showSkill{
        display: inline !important;
    }
}

@media (max-width: 950px) {
    
    .backgroundPicture{
        background: url('../../../../Assets/images/my_pic2.jpg') center 50% / cover no-repeat ;
    }

    .homeContent-placer{
        width: 100%;
        align-items: center;
    }

    .homeContent-placer h1{
        width: 100%;
        margin-top: 20%;
        font-size: 40px ;
        text-align: center;
    }

    .homeContent-placer p{
        width: 70%;
        text-align: center;
        font-size: 20px;
    }

    .chngingSkills{
        text-align: center;
        font-size: 25px;
        display: flex;
    }

    .showSkill-container{
        margin: auto;
        text-align: center;
    }
}

@media (max-width: 650px) {

    .homeContent-placer{
        gap:30px;
    }

    .homeContent-placer h1{
        margin-top: 30%;
        padding-top: 0px;
        font-size: 40px ;
    }

    .chngingSkills-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .showSkill-container{
        margin: auto;
        text-align: center;
    }
}

@media (max-width: 550px) {

    .homeContent-placer h1{
        margin-top: 30%;
        padding-top: 0px;
        font-size: 35px ;
    }

    .chngingSkills-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .homeContent-placer p{
        font-size: 16px;
        text-align: center;
    } 

    .chngingSkills{
        font-size: 20px;
        text-align: center;
        display: flex;
    }

}

@media (max-width:450px) {
    .chngingSkills-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .chngingSkills{
        width: 80%;
    }
}