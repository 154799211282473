
.contactMe-section{
    height: max-content;
    min-height: 730px;
    width: 100%;
    background: url('../../../../Assets/images/bg.png') center 0/ cover no-repeat;
    background-color: rgba(0,0,0,.9);
    font-family: 'Roboto Mono', monospace;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.contactMe-placer{
    height: 100%;
    max-height: max-content;
    width: 100%;
    padding: 5% 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:60px;
}

.contactMe-header{
    color:white;
    display: flex;
    flex-direction: column;
}

.cntctMe-Title{
    margin-bottom: 25px;
    font-weight: 500;
    font-size:40px;
}

.cntctMe-explntion{
    font-size: 20px;
    line-height: 33px;
}

.cntct-form{
    width: 100%;
    min-width: 408px;
    max-width: 1400px;
    padding:40px 30px;
    margin: auto;
    background-color: white;
    display: block;
}

.cntctInputs-container{
    width: 100%;

    display: flex;
    flex-wrap: wrap;
}

.cntctInputs-container > *{
    flex: 50%;
    min-width: 350px;
    line-height: 24px;
}

.nmeEmailPhone-container{
    padding: 0 15px;
    display: flex;
    flex-direction: column;
}

.nmeEmailPhone-container input{
    height: 50px;
    padding: 10px 25px;
    margin-bottom: 30px;
    border: 1px solid rgba(0,0,0,.5);
    background-color: rgba(30,30,30,.9);
    color: rgb(210,210,210);
}

.nmeEmailPhone-container input::placeholder{
    color: rgb(210,210,210);
    font-weight: lighter;
}

.yourMessage-container{
    margin-bottom: 30px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.yourMessage-container textarea{
    min-height: 210px;
    width: 100%;
    border: 1px solid rgba(0,0,0,.5);
    padding: 20px 25px;
    background-color: rgba(30,30,30,.9);
    color: rgb(210,210,210);
    resize: none;
}

.yourMessage-container textarea::placeholder{
    color: rgb(210,210,210);
    font-weight: lighter;
}

.sndMessgeButtn-container{
    width: 100%;
    text-align: center;
}

.sndMessgeButtn-container button{
    height: 50px;
    background-color: rgba(50,50,50,.1);
    position: relative;
    padding-inline: 18px ;
    border: 1px solid rgb(50, 50, 50);
    outline: none;
    color: rgb(50,50,50);
    overflow: hidden;
}

.sndMessgeButtn-container button::after{
    top:0;
    left:0px;
    height: 100%;
    width: 100%;
    content: "Send Message";
    position: absolute;
    padding: 10px 15px;
    background: rgba(30,30,30,.9);
    color: white;
    font-family: 'Roboto Mono', monospace;
    font-weight: 200;
    transform: translateX(-5%);
    opacity: 0;
    visibility: hidden; 
    transition: all .3s;

}

.sndMessgeButtn-container button:hover::after {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

@keyframes sndMessgeButtnFromLeft {
    from{
        transform: translateX(-10%);
    }to{
        transform: translateX(0);
    }
}

@media (max-width:900px) {
    .contactMe-placer{
        padding: 8% 5%;
        gap:40px
    }

    .cntctMe-Title{
        font-size: 35px;
        margin-bottom: 25px;
    }

    .cntctMe-explntion{
        font-size: 18px;
    }
}

@media (max-width:700px) {
    
    .contactMe-placer{
        padding: 8% 5%;
        gap:30px
    }


    .cntctMe-Title{
        font-size: 35px;
        margin-bottom: 25px;
    }

    .cntctMe-explntion{
        font-size: 1rem;
    }

    /* .cntct-form{
        min-width: 300px;
    }

    .cntctInputs-container > *{
        min-width: 250px;
    } */
}

@media (max-width:510px) {

    .cntctMe-Title{
        font-size: 30px;
        margin-bottom: 25px;
    }

    .cntctMe-explntion{
        font-size: 14px;
    }

    .cntct-form{
        min-width: 300px;
        padding: 30px 20px;
    }

    .nmeEmailPhone-container, .yourMessage-container{
        min-width: 300px;
    }
    
    .footer-designer-name{
        margin: 0;
    }
}

@media (max-width:420px) {

    .cntct-form{
        min-width: 250px;
        padding: 30px 20px;
    }

    .nmeEmailPhone-container,
    .yourMessage-container{
        min-width: 250px;
        padding-inline: 0;
    }

}

@media (max-width:350px) {
    .nmeEmailPhone-container,
    .yourMessage-container{
        min-width: 200px;
        padding-inline: 0;
    }

    
    .footer-designer-name{
        text-align: center;
    }
}