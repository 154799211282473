.wbDtls-container{
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    background-color: rgba(0,0,0,.8);
    z-index: 3;
}


.wbDtls-container.closing{
    animation: clsingwbDtls .3s ease-in-out forwards;
}

@keyframes clsingwbDtls {
    from{
        opacity: 1;
    }to{
        opacity: 0;
    }
}

.wbDtls-placer{
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slwlyDrkWbstBg .5s;
}

.wbDtls-window{
    max-height: 80%;
    position: relative;
    background-color: white;
    font-family: 'Roboto Mono', monospace;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    animation: poppingOutWbDtls .3s ease-in-out forwards;
    overflow-y: auto;
    overflow-x: hidden;
}

.wbDtls-window::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.wbDtls-window::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50px;
    border: 2px solid rgba(0, 0, 0, 0.1);
}

.wbDtls-window::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 1);
}

.wbDtls-window::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.6);
}

.wbDtls-window::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.1);
}

@keyframes poppingOutWbDtls {
    from{
        transform: scale(.5);
    }to{
        transform: scale(1);
    }
}

.clseClckedPrjct{
    height: max-content;
    position: absolute;
    top:20px;
    right:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
}

.clseClckedPrjct div{
    height: max-content;
    width: 100%;
    padding: 5px 15px;
    
    background-color: rgba(130,130,130,.6);
    padding-right: 40px;
    border-radius: 30px;
    
    border: 1px solid white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clseClckedPrjct i{
    position: absolute;
    color: rgba(80,80,80,.9);
    font-size: 30px;
    right: 1px;
}

.clseClckedPrjct i::before{
    background-color:white;
    border-radius: 50%;
}


.wbstImages-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.wbstImages {
    width: 100%;
    margin-bottom: 40px;
    position: relative;
    background-color: rgba(30,30,30);
    display: flex;
    justify-content: center;
}

.dotsForImages{
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: -10%;
    margin: auto;
    padding-inline: 10px;
    background-color: rgba(30,30,30,1);
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap:10px;
    transform: translateX-(50%);
    overflow-x: scroll !important;
    overflow-y: hidden;
}

.dotsForImages::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.dotsForImages::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.1);
}

.dotsForImages::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 1);
}

.dotsForImages::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.dotsForImages::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.1);
}

.dot{
    height: 10px;
    width: 10px;
    border: 1px  solid  white;
    border-radius: 50%;
    box-shadow: 0 0 0px 2px;
    font-size: 100px;
    background-color: black;
    transition: .1s;
    cursor: pointer;
    z-index: 1;
    flex-shrink: 0; 
}

.dot.active{
    height: 10px;
    width: 35px;
    
    border-radius: 45px;
}

.prvsImg-container{
    height: 50%;
    width: 20%;
    top:25%;
    left:0;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform: translateX(15%);
    z-index: 1 ;
}

.prvsImg {
    cursor: pointer;
    color: rgba(0,0,0,.6);
    cursor: pointer;
    transition: all .5s;
}

.nxtImg-container{
    height: 50%;
    width: 20%;
    top:25%;
    right:0;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: translateX(-15%);
    z-index: 1;
}

.nxtImg{
    color: rgba(0,0,0,.6);
    cursor: pointer;
    transition: all .5s;
}

.prvsImg:hover, .nxtImg:hover{
    color: rgba(0,0,0,.8);
}

.prvsImg i,.nxtImg i{
    border-radius: 5px;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prvsImg i::before, .nxtImg i::before{
    width: 100%;
    background-color: rgba(255,255,255,.9);
    border-radius: 5px

}

.slctedPrjctImg {
    height: 400px;
    width: max-content;
    max-width: 800px;
}

.wbstDtails{
    width: 800px;
    padding: 20px 30px;
}

.wbstImages-container h2{
    width: 100%;
    margin-bottom: 12px;
    font-weight: 600;
}

.url-container{
    width: 100%;
    padding-bottom: 5px;
    text-align: end;
    color: rgba(0,0,0,.8);
}

.url-container a{
    text-decoration: none;
}

.url-container a:hover{
    text-decoration: underline;
    cursor: pointer;
}

.wbsteExplntn{
    color: rgba(0,0,0,.8);
    font-size: 13px;
    font-weight: 300;
    line-height: 30px;
}

.goTogitHub-button-container{
    width: 100%;
    padding-bottom: 10px;
    text-align: center;
}

.goTogitHub-button-container a button{
    border:1px solid rgba(20,20,20,.5);
    padding: 4px 10px;
    color: black;
    background-color: rgba(50,50,50,.1);
}

.proj-details-angleDown{
    position: sticky;
    left: 47%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: none;
    transition: none;
}

.proj-details-angleDown i{
    font-size: 50px;
    color: rgba(0,0,0,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: bouncing-angleDown 1s infinite ease-in-out;
}

@keyframes bouncing-angleDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

@media (max-width: 950px) {
    .wbDtls-window{
        max-height: 60%;
        min-height: 470px;
    }
    
    .slctedPrjctImg {
        height: 320px;
        max-width: 600px;
    }
    
    .wbstDtails{
        width: 600px;
        padding: 20px 30px;
    }

    .dotsForImages{
        bottom: -12%;
    }

}

@media (max-width: 750px) {
    .wbDtls-window{
        height: 350px;
        max-height: 50%;
        min-height: 350px;
    }

    .clseClckedPrjct{
        top:10px;
        right:10px;
    }

    .clseClckedPrjct div{
        padding: 3px 8px;
        padding-right: 26px;
        font-size: 12px;
    }
    
    .clseClckedPrjct i{
        font-size: 22px;
    }
    
    .slctedPrjctImg {
        height: 210px;
        max-width: 400px;
    }
    
    .dotsForImages{
        bottom: -19%;
    }


    .wbstDtails{
        width: 400px;
        padding: 15px;
    }

    .wbstDtails h2{
        font-size: 20px;
    }
    
    .url-container b{
        font-size: 14px;
    }

    .url-container a{
        font-size: 12px;
    }

    .proj-details-angleDown{
        left: 47%;
    }
    
    .proj-details-angleDown i{
        font-size: 40px;
    }
}


@media (max-width:550px) {
    .wbDtls-window{
        height: 330px;
        max-height: 50%;
        min-height: 330px;
    }

    .clseClckedPrjct{
        top:5px;
        right:5px;
    }

    .slctedPrjctImg {
        height: 180px;
        width: auto;
        max-width: 350px;
    }
    
    .dotsForImages{
        bottom: -22%;
    }

    .prvsImg-container{
        height: max-content;
        top:30%;
    }

    .nxtImg-container{
        height: max-content;
        top:30%;
    }

    .prvsImg i,.nxtImg i{
        border-radius: 5px;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .wbstDtails{
        width: 350px;
        padding: 10px;
    }

    .url-container{
        padding-inline: 10px;
        margin-bottom: 13px;
    }
 
    .url-container b{
        font-size: 13px;
    }

    .url-container a{
        font-size: 12px;
        word-break: break-all;
    }

}

@media (max-width:450px) {
    .wbDtls-window{
        height: 310px;
        max-height: 50%;
        min-height: 310px;
    }

    .clseClckedPrjct{
        display: none;
    }

    .slctedPrjctImg {
        height: 160px;
        width: auto;
        max-width: 320px;
    }

    .wbstDtails{
        width: 320px;
        padding: 10px;
    }

}


@media (max-width:350px) {
    .wbDtls-window{
        height: 290px;
        max-height: 50%;
        min-height: 290px;
    }

    .slctedPrjctImg {
        height: 140px;
        width: auto;
        max-width: 250px;
    }
    
    .dotsForImages{
        bottom: -25%;
    }

    .prvsImg-container{
        height: max-content;
        top:30%;
    }

    .nxtImg-container{
        height: max-content;
        top:30%;
    }

    .prvsImg i,.nxtImg i{
        border-radius: 5px;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .wbstDtails{
        width: 250px;
        padding: 10px;
    }

    .url-container{
        padding-inline: 10px;
        margin-bottom: 13px;
    }
 
    .url-container b{
        font-size: 13px;
    }

    .url-container a{
        font-size: 12px;
        word-break: break-all;
    }

    .proj-details-angleDown{
        left: 45%;
    }
    
    .proj-details-angleDown i{
        font-size: 30px;
    }

}

