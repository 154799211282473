.portfolio-section{
    height: max-content;
    min-height: 730px;
    width: 100%;
    background-color: white;
    font-family: 'Roboto Mono', monospace;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: height 1s;
}

.portfolio-container{
    display: flex;
    flex-direction: column;
    gap:20px
}

.portfolio-placer{
    height: 100%;
    max-height: max-content;
    width: 100%;
    padding: 5% 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:60px;
}

.myPrftlio-title{
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 40px;
}

.prtflio-explntion{
    color: rgb(120,120,120);
    font-size: 20px;
    line-height: 33px;
}


.prtflioTabs-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap:8px;
}

.prtflioTabs-container div {
    width: max-content;
    padding: 12px 40px;
    border:1px solid rgba(0,0,0,.1);
    background-color: white;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activeTab{
    box-shadow: 0 0 10px rgba(0,0,0,.4);
    background-color: rgba(50,50,50,1) !important;
    color: white;
    transition: all .1s;
}

.fade-in {
    opacity: 1;
    transition: opacity .2s ease-in;
}

.fade-out {
    opacity: 0;
    transition: opacity .2s ease-out;
}

.projects-container{
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fit, 1fr);
    gap: 20px;
    overflow: hidden;
    transition: .1s;
}

.project{
    width: 100%;
    position: relative;
    padding:10px;
    text-align: start;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    opacity: 0;
    animation: slwlyPrjctShwng 0.1s ease forwards;
}

@keyframes slwlyPrjctShwng {
    0% {
        opacity: 0;
        transform: translateX(-20px) scale(0.95);
    }
    100% {
        opacity: 1;
        transform: translateX(0) scale(1);
    }
}

.project h2{
    font-size: 20px;
}

.project-item-center{
    margin: 0 auto;
}

.imgProj-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.imgProj-container:hover  .viewProj-hover{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    animation: slwlyShwngVwWbste .7s forwards;
}

@keyframes slwlyShwngVwWbste {
    from{
        opacity: 0;
        transform: translateY(5%);
    }to{
        opacity: 1;
        transform: translateY(0);
    }
}

.imgProj{
    height: 100%;
    width: 100%;
    position:relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.viewProj-hover{
    height: 92%;
    width: 95%;
    background-color: rgba(0,0,0,.6);
    position: absolute;
    z-index: 1;
    display: none;
}

.eyeSee-container{
    height: max-content;
    width: max-content;
    padding: 10px;
    border: 1px solid rgba(150,150,150);
    background-color: rgba(0,0,0,.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: .2s all;
}

.eyeSee-container:hover{
    cursor: pointer;
    background-color: rgba(50,50,50,.9);
    border: 1px solid rgba(50,50,50)
}

.viewProj-hover i{
    padding: 0;
    color: white;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eyeSee-container div{
    color: white;
    font-size: 12px;
}


.project img {
    width: 100%;
    max-width: 480px;
    opacity: 0;
    animation: slwlyPrjctShwng 0.1s ease forwards;
}


.projField{
    width: 100%;
    text-align: end;
    font-size: 16px;
    font-weight: 100;
    color: rgba(50,50,50,.8);
}

@media (max-width:1200px) {
    .projects-container{
        grid-template-columns: repeat(2, 1fr);
    }

    .portfolio-placer{
        padding: 8% 5%;
    }
}

@media (max-width:1100px) {
    .prtflioTabs-container{
        display: flex;
        justify-content: center;
    }
}

@media (max-width:700px) {
    

    .myPrftlio-title{
        font-size: 35px;
    }

    .prtflio-explntion{
        font-size: 1rem;
    }

    .portfolio-placer{
        gap:30px;
    }

    .prtflioTabs-container{
        gap:0
    }

    .prtflioTabs-container div{
        padding: 10px 30px;
        font-size: 14px;
    }

}

@media (max-width:510px) {

    .myPrftlio-title{
        font-size: 30px;
    }

    .prtflio-explntion{
        font-size: 14px;
    }


    .prtflioTabs-container div{
        padding: 8px 20px;
    }

    .projects-container{
        grid-template-columns: repeat(1, 1fr);
        gap:5px
    }

    .project h2{
        font-size: 1rem;
    }
}