.lptpPg-container{
    height: 100%;
    width: 100%;
    background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 100% / cover no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.lptpPg-content {
    height: 100%;
    width: 100vw;
    min-width: 710px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
}

.screen-holder{
    height: 40%;
    min-height: 350px;
    width: 100%;
    perspective: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 3;
}

.screen-container {
    height: 450px;
    width: 650px;
    padding-top: 1%;
    perspective: 1000px;
    background-color: rgba(30,30,30,1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
    font-size: 20px;
    font-weight: bold;
    border: 2px solid #333;
    border-radius: 10px; 
    transform-origin: bottom;
    animation: openingLaptop 5s;
}

.acer-laptop {
    height: 10%;
    width: 100%;
    font-family: sans-serif;
    font-size: 1.5rem;
    font-style: italic;
    color: black;
    text-shadow: 
        -1px -1px 0 rgba(200,200,200,.5),
         1px -1px 0 rgba(200,200,200,.5),
        -1px  1px 0 rgba(200,200,200,.5),
         1px  1px 0 rgba(200,200,200,.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes openingLaptop {
    from{
        transform: rotateX(-70deg);
    }to{
        transform: rotateX(0deg);
    }
}

.keyboard-holder{
    height: 40%;
    min-height: 350px;
    width: 100%;
    perspective: 1000px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index:2 ;
}

.laptop-keyboard {
    height: 450px;
    width: 650px;
    background-color: rgba(30,30,30,1);
    border: 2px solid rgba(70, 70, 70, .8);
    border-bottom: 5px solid rgba(70, 70, 70, .8);;
    border-radius: 10px;
    color: #333;
    font-size: 20px;
    font-weight: bold;
    transform-origin: top;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transform: rotateX(60deg);
    z-index: 1;
}

.exhaust-container{
    height: 10%;
    width: 100%;
    padding-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exhausts{
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(100,6px);
    grid-template-rows: repeat(4, 9px);
}

.exhaust{
    height: 5px;
    width: 4px;
    background-color: rgba(100,100,100,.6);
    border-radius: 50px;
    transform: skewx(45deg);
}

.mousepad-container{
    height: 30%;
    width: 100%;
    padding-bottom: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mousepad{
    height: 95%;
    width: 35%;
    border: 2px solid rgba(150,150,150,.5);
    border-radius: 5px;
    background-color: rgba(50,50,50,1);
}

.laptop-volume{
    height: 10%;
    width: 117%;
    position: absolute;
    border-radius: 50%;
    border: 5px solid rgb(150,150,150);
    background-color: black;
    top: 96%;
    transform: rotateX(-10deg)!important;
    clip-path: polygon(10% 100%, 90% 100%, 92% 50%, 8% 50%);
}

.tablePg-container{
    height: 40%;
    width: 150%;
    bottom: 0;
    position: absolute;
    background-color: antiquewhite;
    border: 2px solid rgba(150,150,150,.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    clip-path: polygon(25% 0, 75% 0, 100% 100%, 0% 100%);
}

.headset-image{
    height: 70%;
    width: max-content;
    position: absolute;
    left: -10%;
    bottom:-20%;
    z-index: 2;
    transform: scaleX(-1);
}



@media (max-height: 850px) {
    .tablePg-container{
        height: 45%;
        clip-path: polygon(20% 0, 80% 0, 100% 100%, 0% 100%);
    }
}

@media (max-height: 800px) {

    .tablePg-container{
        height: 48%;
        clip-path: polygon(20% 0, 80% 0, 100% 100%, 0% 100%);
    }
}

@media (max-height: 720px) {
    .screen-holder{
        height: 40%;
        min-height: 300px;
        width: 100%;
        perspective: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .screen-container{
        height: 100%;
        width: 550px;
        z-index: 3;
    }

    .exhaust-container{
        height: 10%;
        width: 100%;
        padding-top: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .exhausts{
        grid-template-columns: repeat(100,5px);
        grid-template-rows: repeat(4, 9px);
    }

    .exhaust{
        height: 3px;
        width: 2px;
        background-color: rgba(100,100,100,.6);
        border-radius: 50px;
        transform: skewx(45deg);
    }
    

    .laptop-keyboard{
        width: 550px;
        transform: rotateX(0deg);
    }

}

@media (max-height: 670px), (max-width: 950px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 110% / cover no-repeat;
    }

    .screen-holder{
        height: 30% !important;
        min-height: 250px;
    }

    .screen-container{
        width: 400px;
    }

    .keyboard-holder{
        height: 30% !important;
        min-height: 250px;
    }

    .laptop-keyboard{
        width: 400px !important;
    }

    .exhaust-container{
        height: 13%;
        padding: 3%;
    }

    .exhausts{
        grid-template-columns: repeat(100,3px);
        grid-template-rows: repeat(4, 5px);
    }

    .mousepad-container{
        height: 27%;
        padding: 0;
    }

    .mousepad{
        height: 90% !important;
    }
}



@media (max-width: 1400px) and (max-height:1000px){
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 110% 125% no-repeat;
    }

    .tablePg-container{
        height: 42%;
        clip-path: polygon(18% 0, 82% 0, 100% 100%, 0% 100%);
    }
}

@media (max-width: 1200px) and (max-height:1000px){
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 110% 120% no-repeat;
    }

    .tablePg-container{
        height: 40%;
        clip-path: polygon(18% 0, 82% 0, 100% 100%, 0% 100%);
    }
}


@media (max-width: 1000px) and (max-height:1000px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 110% 120% no-repeat;
    }

    .tablePg-container{
        height: 42%;
        clip-path: polygon(15% 0, 85% 0, 100% 100%, 0% 100%);
    }
}

@media (max-width: 800px) and (max-height:1000px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 130% 120% no-repeat;
    }

    .tablePg-container{
        height: 35%;
        clip-path: polygon(20% 0, 80% 0, 100% 100%, 0% 100%);
    }
}

@media (max-width: 600px) and (max-height:1000px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 180% 120% no-repeat;
    }

    .tablePg-container{
        height: 35%;
        clip-path: polygon(20% 0, 80% 0, 100% 100%, 0% 100%);
    }
}


@media (max-width: 1200px) and (max-height:900px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 110% 135% no-repeat;
    }

    .tablePg-container{
        height: 45%;
        clip-path: polygon(15% 0, 85% 0, 100% 100%, 0% 100%);
    }
}

@media (max-width: 1100px) and (max-height:900px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 110% 135% no-repeat;
    }

    .tablePg-container{
        height: 50%;
        clip-path: polygon(15% 0, 85% 0, 100% 100%, 0% 100%);
    }
}


@media (max-width: 950px) and (max-height:900px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 120% 130% no-repeat;
    }

    .tablePg-container{
        height: 35%;
        clip-path: polygon(20% 0, 80% 0, 100% 100%, 0% 100%);
    }
}


@media (max-width: 1200px) and (max-height:700px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 120% 130% no-repeat;
    }

    .tablePg-container{
        height: 50%;
        clip-path: polygon(20% 0, 80% 0, 100% 100%, 0% 100%);
    }
}




@media (max-width: 950px) and (max-height:700px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 120% 130% no-repeat;
    }

    .tablePg-container{
        height: 45%;
        clip-path: polygon(20% 0, 80% 0, 100% 100%, 0% 100%);
    }
}


@media (max-width: 800px) and (max-height:700px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 130% 120% no-repeat;
    }

    .tablePg-container{
        height: 40%;
        clip-path: polygon(20% 0, 80% 0, 100% 100%, 0% 100%);
    }
}

@media (max-width: 800px) and (max-height:600px) {
    .lptpPg-container{
        background: url('../../Assets/images/lptp_pg/lptop_bg/wall_wind_bg2.jpg') center 200% / 180% 130% no-repeat;
    }

    .tablePg-container{
        height: 50%;
        clip-path: polygon(20% 0, 80% 0, 100% 100%, 0% 100%);
    }
}